<template>
  <div>
    <br>
    <div class="row">
      <div class="col-md-12">
        <div class="row" v-if="readyToLive && approvers([ 2 ]) && prove_of_payments.length > 0">
          <div class="col-md-9"></div>
          <div class="col-md-3">
            <ul class="nav nav-pills" style="display: flex;justify-content: end;margin-top: 1rem;">
              <li class="nav-item waves-effect waves-light" role="presentation">
                <b-button @click="applyCase('GO LIVE')" :variant="check_class('GO LIVE', 'success')">
                  <span class="d-none d-sm-block">GO LIVE</span>
                </b-button>
              </li>
            </ul>
          </div>
        </div>
        
        <div class="row" v-else-if="readyToLive && !prove_of_payments.length > 0">
          <div class="col-md-9"></div>
          <div class="col-md-3">The information has been sent to Lender successfully. This customer is in the process of
            going live. Please note that the lender will have to upload the proof of payment before the go live button will appear.</div>
        </div>

        <div class="row" v-else-if="approvers([ 2 ]) && status == 1 ">
          <div class="col-md-9"></div>
          <div class="col-md-3" style="display: flex;justify-content: end;">
            <span style="margin-top: 0.5rem;">Select Year: &nbsp; 
              <ul class="nav nav-pills" style="display: flex; justify-content: start">
                <b-form-select v-model="selectedYears" style="width: 5rem;" >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </b-form-select>
              </ul>
            </span>
          </div>
        </div>

        <div class="row" v-else-if="approvers([3])">
          <div class="col-md-8">
          </div>

          <div class="col-md-4"
            v-if="(!AgentCommentedInCase('Enquiry')) && status == 0">
            <span>*Required Agent to submit the form.</span>
          </div>
          <div class="col-md-4" v-if="status == 1 && (!AgentCommentedInCase('Loan'))">
            <span>*Required Agent to complete the checklist.</span>
          </div>
          <div class="col-md-4" v-else-if="checkIfInstallIsVerified && status == 2">
            <span>*You need to VERIFY installer in Require Install before Proceed.</span>
          </div>
          <div class="col-md-4"
            v-else-if="status == 3 && (!AgentCommentedInCase('Final Inspection & Loan Disbursement')) && !(checkIfInstallIsVerified && !allChecklistIsCompleted)">
            <span>*Required Agent to submit the form.</span>
          </div>
          <div class="col-md-4" v-else>
            <ul class="nav nav-pills" style="display: flex;justify-content: end;">
              <li v-if="approvers([3]) && status == 4" class="nav-item waves-effect waves-light" role="presentation">
                <b-button @click="applyCase('APPROVE')" :variant="check_class('APPROVE', 'success')">
                  <span class="d-none d-sm-block">GO LIVE</span>
                </b-button>
              </li>
              <li v-else-if="approvers([3])" class="nav-item waves-effect waves-light" role="presentation">
                <b-button @click="applyCase('VERIFY')" :variant="check_class('VERIFY', 'success')">
                  <span class="d-none d-sm-block">{{ isVerify(status) ? textRendering('VERIFY') : 'VERIFY' }}</span>
                </b-button>
              </li>
              <li v-else class="nav-item waves-effect waves-light" role="presentation">
                <b-button @click="applyCase('APPROVE')" :variant="check_class('APPROVE', 'success')">
                  <span class="d-none d-sm-block">APPROVE</span>
                </b-button>
              </li>
              &nbsp;
              <li class="nav-item waves-effect waves-light button-items" role="presentation">
                <b-button @click="applyCase('REJECT')" :variant="check_class('REJECT', 'danger')">
                  <span class="d-none d-sm-block">REJECT</span>
                </b-button>
              </li>
            </ul>
          </div>
        </div>

        <div class="row" v-else-if="approvers([4]) && (status == 1 || status == 3)">
          <div v-if="status == 1" class="col-md-9">
            <span class="badge bg-danger">Select Lender</span>
            <b-form-group label-for="lenders" class="mb-3">
              <b-form-radio v-for="lender in lenders_selectables" id="lenders" v-model="lenders[0]" name="lenders"
                :value="lender.value" :key="lender.value">
                {{ lender.text }}
              </b-form-radio>
            </b-form-group>
          </div>
          <div v-else class="col-md-9">

          </div>

          <div class="col-md-3">
            <ul class="nav nav-pills" style="display: flex;justify-content: end;">
              <span v-if="isVerify(status) == 0" style="margin-bottom: 0.5rem;">*The authorizer can approve or reject,
                once the agent has submitted the loan schedule and it has been verified by the verifier.</span>
              <li class="nav-item waves-effect waves-light" role="presentation">
                <b-button :disabled="isVerify(status) == 0" @click="applyCase('AUTHORIZE')"
                  :variant="check_class('AUTHORIZE', 'success', isVerify(status) == 0)">
                  <span class="d-none d-sm-block">{{ isAuthorize(status) ? textRendering('AUTHORIZE') : 'AUTHORIZE'
                  }}</span>
                </b-button>
              </li>
              &nbsp;
              <li class="nav-item waves-effect waves-light button-items" role="presentation">
                <b-button :disabled="isVerify(status) == 0" @click="applyCase('REJECT')"
                  :variant="check_class('REJECT', 'danger', isVerify(status) == 0)">
                  <span class="d-none d-sm-block">REJECT</span>
                </b-button>
              </li>
            </ul>
          </div>
        </div>

        <div class="row" v-else-if="approvers([5])">
          <div class="col-md-8">
            <div v-if="status == 2">
              <b-form-input v-model="imei" placeholder="Enter IMEI" required type="text"></b-form-input>
            </div>
          </div>
          <div class="col-md-4">
            <ul class="nav nav-pills" style="display: flex;justify-content: end;">
              <li class="nav-item waves-effect waves-light" role="presentation">
                <b-button @click="applyCase('CONFIRM')" :variant="check_class('CONFIRM', 'success')">
                  <span class="d-none d-sm-block">{{ myLastComment() == 'CONFIRM' ? textRendering('CONFIRM') : 'CONFIRM'
                  }}</span>
                </b-button>
              </li>&nbsp;
              <li class="nav-item waves-effect waves-light" role="presentation">
                <b-button @click="applyCase('RE-ARRANGE')" :variant="check_class('RE-ARRANGE', 'warning')">
                  <span class="d-none d-sm-block">{{ myLastComment() == 'RE-ARRANGE' ? textRendering('RE-ARRANGE') :
                    'RE-ARRANGE' }}</span>
                </b-button>
              </li>&nbsp;
              <li class="nav-item waves-effect waves-light" role="presentation">
                <b-button @click="applyCase('CANCEL')" :variant="check_class('CANCEL', 'danger')">
                  <span class="d-none d-sm-block">{{ myLastComment() == 'CANCEL' ? textRendering('CANCEL') : 'CANCEL'
                  }}</span>
                </b-button>
              </li>
            </ul>
          </div>
          <div class="col-md-12" v-if="result == 'RE-ARRANGE'">
            <div class="row" style="margin-top: 1rem;">
              <div class="col-md-6">
                <b-form-group label="Book Date" label-for="book_date" class="mb-3">
                  <b-form-input v-model="book_date" id="book_date" type="date"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label="Book Time" label-for="book_date" class="mb-3">
                  <b-form-input v-model="book_time" id="book_date" type="time"></b-form-input>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-else-if="approvers([6]) && (status == 1 || status == 3)">
          <div class="row">
            <div class="col-md-12">
              <div v-for="{ label, key }, i in parsedChecklist" :key="i">
                <div class="row" style="margin-bottom: 0.5rem;">
                  <div class="col-md-3" style="display: flex;align-items: center;">
                    <span>{{ `${i + 1}.` }} {{ label }}</span>
                  </div>
                  <div class="col-md-2">
                    
                  </div>
                  <div class="col-md-1">
                    <ul class="list-inline mb-0">
                      <li class="list-inline-item">
                        <a v-if="i !== 0 && !is_lender" @click="deleteChecklist(i)" href="#" class="px-2 text-danger"><i
                            class="uil uil-trash-alt font-size-18"></i></a>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-6">
                    <div v-if="uploadedLabelFile(label)" style="display: flex;align-items: center;">
                      <div>
                        <span v-if="uploadedLabelFile(label).label" class="badge bg-success">{{ uploadedLabelFile(label).label }}</span>
                        <span v-else class="badge bg-danger">Others</span> &nbsp;
                      </div>
                      <a style="width: 50%;white-space: nowrap;display: block;overflow-x: hidden;text-overflow: ellipsis;" :href="uploadedLabelFile(label).url" target="_blank">{{ fileName(uploadedLabelFile(label).url) }}</a>
                      <a v-if="!is_lender" @click="deleteFile(uploadedLabelFile(label).id)" href="#" class="px-2 text-danger"><i
                          class="uil uil-trash-alt font-size-18"></i></a>
                    </div>
                    <input v-else-if="!uploadedLabelFile(label)" type="file" class="form-control" @change="uploadFileToLabel($event, label)" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <ul class="nav nav-pills" style="display: flex;justify-content: end;">
                <li class="nav-item waves-effect waves-light" role="presentation">
                  <b-button @click="applyCase('APPROVE')" :variant="check_class('APPROVE', 'success')">
                    <span class="d-none d-sm-block">{{ isApprove(status) ? textRendering('APPROVE') : 'APPROVE' }}</span>
                  </b-button>
                </li>
                &nbsp;
                <li class="nav-item waves-effect waves-light" role="presentation">
                  <b-button @click="applyCase('MORE INFORMATION')" :variant="check_class('MORE INFORMATION', 'warning')">
                    <span class="d-none d-sm-block">MORE INFORMATION</span>
                  </b-button>
                </li>&nbsp;
                <li class="nav-item waves-effect waves-light button-items" role="presentation">
                  <b-button @click="applyCase('REJECT')" :variant="check_class('REJECT', 'danger')">
                    <span class="d-none d-sm-block">REJECT</span>
                  </b-button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <br>
      </div>

      <div class="col-md-12">
        <b-form-group :label="`Create Comment [${role_selectables[role_id - 1]?.text}] `" label-for="remark" class="mb-3">
          <b-form-textarea v-model="comment" id="remark" type="text"></b-form-textarea>
        </b-form-group>
      </div>

      <div class="col-md-6">
        <b-button :disabled="submitHandler" @click="onSubmit()" variant="primary" class="float-right"
          style="margin-right: 1rem">Submit
        </b-button>
      </div>
      <!-- 
      <div class="col-md-6" style="display: flex; justify-content: end">
        <b-button variant="primary" class="float-right">cancel</b-button>
      </div> -->
    </div>

    <br>
    <div class="row">
      <div class="col-md-12">
        <div v-for="comment in hiddenComments" :key="comment.id" class="card mb-3">
          <div class="row">
            <div class="col-md-10">
              <div class="card-body">
                <div class="card-title">
                  <span class="badge bg-success">{{ comment.case }}</span> &nbsp;
                  [ {{ comment.staff?.name }} &nbsp;
                  <span class="badge bg-primary">{{ comment.staff?.role?.name }}</span> ]&nbsp;-
                  <span v-if="['APPROVE', 'CONFIRM', 'VERIFY', 'AUTHORIZE'].includes(comment.result)"
                    class="badge bg-success">{{ textRendering(comment.result) }}</span>
                  <span v-else-if="['REJECTED', 'MORE INFORMATION'].includes(comment.result)" class="badge bg-warning">{{
                    textRendering(comment.result) }}</span>
                  <span v-else class="badge bg-danger">{{ textRendering(comment.result) }}</span>
                  &nbsp;
                  <span class="badge bg-warning">{{
                    render_datetime(comment.created_at)
                  }}
                  </span>
                </div>
                <span>
                  {{ comment.comment }}
                  <b-button variant="success"
                    v-if="commentIsIncludeDateTime(comment.comment) && role_id == 2 && !is_confirmed" @click="newTimeAccepted(
                      {
                        date: comment.comment.split('[')[1].split(']')[0].split(' ')[0],
                        time: comment.comment.split('[')[1].split(']')[0].split(' ')[1]
                      }
                    )">Accept New Date-Time</b-button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <br>
    <div v-if="user_profile?.role_id == 1" class="row">
      <div class="col-md-12">
        <b-form-group label="Select Role [Admin Only]" label-for="remark" class="mb-3">
          <b-form-select label="Select Role" v-model="role_id" :options="role_selectables"></b-form-select>
        </b-form-group>
      </div>
    </div>

  </div>
</template>

<script>
import moment from 'moment';
import systemService from '@/api/systemService';
import staffService from '@/api/staffService';
import Swal from 'sweetalert2';
import axios from 'axios';

export default {
  props: {
    form_data: {
      type: Object,
      default: () => null
    },
    allChecklistIsCompleted: {
      type: Boolean,
      default: () => false
    },
    progressPrevention: {
      type: Boolean,
      default: () => false
    },
    customer_id: {
      type: Number,
      default: () => null
    },
    lender_id: {
      type: Number,
      default: () => null
    },
    comments: {
      type: Array,
      default: () => []
    },
    status: {
      type: Number,
      default: () => 0
    },
    is_confirmed: {
      type: Boolean,
      default: () => false
    },
    lender_checklist: {
      type: String,
      default: () => null
    },
    prove_of_payments: {
      type: Array,
      default: () => []
    }
  },
  name: 'CommentSection',
  data() {
    return {
      selectedYears: null,
      comment: null,
      case: null,
      role_selectables: [
        { text: 'Admin', value: 1 },
        { text: 'Agent', value: 2 },
        { text: 'Verifier', value: 3 },
        { text: 'Authorizer', value: 4 },
        { text: 'Installer', value: 5 },
        { text: 'Lender', value: 6 }
      ],
      lenders: [],
      lenders_selectables: [
        { text: 'Lender 1', value: 6 },
      ],
      book_date: null,
      book_time: null,
      role_id: null,
      user_profile: null,
      loading: false,
      process_name: ['Enquiry', 'Loan Schedule & Document', 'Require Install', 'Final Inspection & Loan Disbursement', 'Live'],
      result: null,
      imei: null,

      //Lender Dropzone
      uploadedFiles: [],
      checklistValue: {
        0: 0
      },
      default_options: [
        {
          text: 'No Status',
          value: 0,
        },
        {
          text: 'In Progress',
          value: 1,
        },
        {
          text: 'Completed',
          value: 2,
        },
      ]
    }
  },
  computed: {
    is_lender() {
      return this.approvers([6])
    },
    parsedChecklist() {
      return JSON.parse(this.lender_checklist)
    },
    yearIsSelected() {
      if (this.form_data?.loan?.selected_years) {
        return this.form_data?.loan?.selected_years
      } else {
        return false
      }
    },
    readyToLive() {
      return this.comments.filter((e) => {
        return e.case.includes('Inspection')
      }).filter((e) => {
        return e.result == 'AUTHORIZE' && e.staff?.role_id == 4
      }).length > 0
    },
    bookingIsConfirmed() {
      return this.comments.filter((e) => {
        return e.case == 'Require Install'
      }).filter((e) => {
        return e.result == 'CONFIRM'
      }).length > 0
    },
    checkIfInstallIsVerified() {
      if (this.status == 3) {
        return this.comments.filter((e) => {
          return e.case == 'Require Install'
        }).filter((e) => {
          return e.result == 'VERIFY'
        }).length > 0
      } else {
        return false
      }
    },
    hiddenComments() {
      if (this.role_id == 5) {
        return this.comments.filter((e) => {
          return e.case == 'Require Install'
        })

      } else {
        return this.comments
      }
    },
    submitHandler() {
      if (this.progressPrevention) {
        return this.progressPrevention || this.comment == null
      } else if (this.status == 3) {
        if (this.role_id == 2) {
          return this.allChecklistIsCompleted == false || this.comment == null
        } else {
          return this.checkIfInstallIsVerified == false || this.comment == null
        }
      } else {
        if (this.status == 1 && this.approvers([4])) {
          return this.comment == null || this.lenders.length == 0 || this.result == null
        } else if (this.approvers([3, 4])) {
          return (this.result == null || this.comment == null)

        } else {
          return !this.comment
        }
      }
    },
  },
  methods: {
    uploadFileToLabel(file, label) {
			const uploadUrl = 'https://infinity-backend.braiven.com/upload'
			const formData = new FormData();
			formData.append('file', file.target.files[0]);

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			};

			axios.post(uploadUrl, formData, config)
				.then((response) => {
					const {
						files: {
							file: { url }
						}
					} = response.data
					this.uploadedFiles.push({
						label,
						url
					});
				})
				.catch((error) => {
					console.log(error);
				});
		},
    fileName(url) {
      const split_url = url.split('/');
      return split_url[split_url.length - 1];
    },
    uploadedLabelFile (label) {
			return this.prove_of_payments.find((e) => e.label == label);
		},
    AgentCommentedInCase(caseStep) {
      const comment = this.comments.filter(e => {
        return e.staff?.role_id == 2 && e.case.includes(caseStep)
      })
      return comment.length > 0
    },
    newTimeAccepted({ date, time }) {
      this.$emit('newTimeAccepted', { date, time })
    },
    commentIsIncludeDateTime(text) {
      return text ? text.includes('[') && text.includes(']') : false
    },
    textRendering(text) {
      //Convert word to past tense
      const past_tense = {
        'APPROVE': 'APPROVED',
        'VERIFY': 'VERIFIED',
        'AUTHORIZE': 'AUTHORIZED',
        'CONFIRM': 'CONFIRMED',
        'REJECT': 'REJECTED',
        'RE-ARRANGE': 'RE-ARRANGED',
        'CANCEL': 'CANCELLED',
        'MORE INFORMATION': 'MORE INFORMATION'
      }

      return past_tense[text] ? past_tense[text] : text
    },
    isApprove(status) {
      const result = this.comments.filter((e) => {
        return e.case == this.process_name[status]
      }).filter((e) => {
        return e.result == 'APPROVE'
      })

      return result.length
    },
    isAuthorize(status) {
      const result = this.comments.filter((e) => {
        return e.case == this.process_name[status]
      }).filter((e) => {
        return e.result == 'AUTHORIZE'
      })

      return result.length
    },
    isVerify(status) {
      const result = this.comments.filter((e) => {
        return e.case == this.process_name[status]
      }).filter((e) => {
        return e.result == 'VERIFY'
      })

      return result.length
    },
    async role_checker() {
      this.loading = true
      this.user_profile = await systemService.getProfile()
      this.role_id = this.user_profile.role_id
    },
    approvers(role_ids) {
      return role_ids.includes(this.role_id)
    },
    onSubmit() {
      const body = {
        comment: this.comment,
        case: this.process_name[this.status],
        status: this.status,
        result: this.result
      }

      if (this.role_id) {
        body.role_id = this.role_id
      }

      if (this.role_id == 5 && this.result == 'CONFIRM') {
        body.is_confirmed = true
      }

      if (this.uploadedFiles.length) {
        body.prove_of_payments = this.uploadedFiles
      }

      if (this.book_date && this.book_time) {
        body.comment = `${this.comment} [${this.book_date} ${this.book_time}]`
      }

      if (this.imei) {
        body.imei = this.imei
      }

      if (this.lenders.length) {
        body.lenders = this.lenders
      }

      if (this.selectedYears) {
        body.selectedYears = this.selectedYears
      }

      Swal.fire({
        title: 'Are you sure (Y/N)?',
        text: this.result ? `Your comment will be submitted with "${this.result}" condition.` : 'Your comment will be submitted.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        cancelButtonColor: '#d33'
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            toast: true,
            timer: 3000,
            position: 'top-end',
            icon: 'success',
            title: 'Submitting...',
            showConfirmButton: false
          })
          const res = await staffService.createComment(this.customer_id, body)
          if (res) {
            if (this.result) {
              Swal.fire(
                'Success!',
                `You have ${this.result} this application and the other party has been informed.`,
                'success'
              )

            } else {
              Swal.fire(
                'Success!',
                'Your comment has been submitted.',
                'success'
              )
            }
            this.$emit('refresh')
          }
        }
      })
    },
    check_class(value, class_name, disabled) {
      if (disabled) {
        return `grey-disabled`
      } else {
        if (this.result) {
          return (this.result == value) ? class_name : `outline-${class_name}`
        } else {
          return this.myLastComment() == value ? class_name : `outline-${class_name}`
        }
      }
    },
    myLastComment() {
      const result = this.comments.filter((e) => {
        return e.staff_id == this.user_profile.id && e.case == this.process_name[this.status]
      })

      return result[0]?.result
    },
    isMyComment(comment) {
      return comment.staff_id == this.user_profile.id
    },
    applyCase(case_text) {
      this.result = case_text
    },
    render_datetime(datetime) {
      return moment(datetime).format('DD/MM/YYYY HH:mm:ss');
    },
    async getLenders() {
      const { data } = await staffService.index(6)
      this.lenders_selectables = data.map(e => {
        return {
          text: e.name,
          value: e.id
        }
      })
      if (this.lender_id) {
        this.lenders = [this.lender_id]
        this.result = 'AUTHORIZE'
      }
    }
  },
  mounted() {
    this.role_checker()
    this.getLenders()
    if (this.yearIsSelected) {
      this.selectedYears = this.yearIsSelected
    }
  }
};
</script>

<style scoped>
.grey-disabled {
  background-color: #e9ecef !important;
  border-color: #e9ecef !important;
  color: #6c757d !important;
  cursor: not-allowed !important;
}
</style>