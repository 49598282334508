<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import customerService from '@/api/customerService';
import systemService from '@/api/systemService';
import Swal from 'sweetalert2';
import CommentSection from '@/components/comment-section.vue';
import axios from 'axios';

export default {
  name: 'final-inspection',
  components: {
    vueDropzone: vue2Dropzone,
    CommentSection
  },
  props: {
    form_data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    edit_permission: {
      type: Boolean,
      default: false,
    },
    getData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      remarks: '',
      checklist_json: `[{
            "key": 0,
            "label": "Device Installation",
            "value": 2
      }]`,
      lender_checklist: `[{
            "key": 0,
            "label": "Proof of Payment",
            "value": 1
      }]`,
      checklist_value: null,
      new_checklist_label: '',
      new_lender_checklist_label: '',
      default_options: [
        {
          text: 'No Status',
          value: 0,
        },
        {
          text: 'In Progress',
          value: 1,
        },
        {
          text: 'Completed',
          value: 2,
        },
      ],
      checklistValue: {
        0: 0
      },
      checklistValueLender: {
        0: 0
      },
      dropzoneOptions: {
        url: 'https://infinity-backend.braiven.com/upload',
        thumbnailWidth: 150,
        maxFilesize: 2,
      },
      existingFiles: [],
      prove_of_payments: [],
      uploadedFiles: [],
      comments: [],
      device_install_text: '',
      changesTimeout: null,
    };
  },
  computed: {
    customer_id() {
      return this.$route.query.id;
    },
    parsedChecklist() {
      return JSON.parse(this.checklist_json)
    },
    parsedLenderChecklist() {
      return JSON.parse(this.lender_checklist)
    },
    installerIsSubmitted() {
      const target = this.comments.filter(e => e.case == 'Require Install' && e.staff.role_id == 5)
      const first_comment = target[0]
      if (first_comment) {
        return first_comment.result == 'CONFIRM'
      } else {
        return false
      }
    },
    installerIsRearrange() {
      const target = this.comments.filter(e => e.case == 'Require Install' && e.staff.role_id == 5)
      const first_comment = target[0]
      if (first_comment) {
        return first_comment.result == 'RE-ARRANGE'
      } else {
        return false
      }
    },
    allChecklistIsCompleted() {
      const parsed_checklist = JSON.parse(this.checklist_json);
      return parsed_checklist.every(e => e.value == 2)
    }
  },
  methods: {
    uploadFileToLabel(file, label) {
			const uploadUrl = 'https://infinity-backend.braiven.com/upload'
			const formData = new FormData();
			formData.append('file', file.target.files[0]);

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			};

			axios.post(uploadUrl, formData, config)
				.then((response) => {
					const {
						files: {
							file: { url }
						}
					} = response.data
					this.uploadedFiles.push({
						label,
						url
					});
					this.changes();
				})
				.catch((error) => {
					console.log(error);
				});
		},
    uploadedLabelFile (label) {
			return this.existingFiles.find((e) => e.label == label);
		},
    uploadedProveOfPayment (label) {
      return this.prove_of_payments.find((e) => e.label == label);
    },
    changes() {
      clearTimeout(this.changesTimeout);
      this.changesTimeout = setTimeout(() => {
        this.saveInspection()
      }, 2000);
    },
    formValidation(e, type = 'text') {
      const emailRegex = /\S+@\S+\.\S+/;

      switch (type) {
        case 'email':
          return e !== null && e !== undefined && e !== '' && emailRegex.test(e);
        default:
          return e !== null && e !== undefined && e !== '';
      }
    },
    deleteFile(id) {
      Swal.fire({
        title: 'Confirm',
        text: 'Are you sure you want to delete this file?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async (result) => {
        if (result.value) {
          const response = await systemService.deleteFile(id);
          if (response) {
            Swal.fire({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              icon: 'success',
            });
            this.$emit('refresh')
            this.assignData()
          }
        }
      })
    },
    fileName(url) {
      const split_url = url.split('/');
      return split_url[split_url.length - 1];
    },
    onFileUploadSuccess(file, response) {
      const { files: { file: { url } } } = response
      this.uploadedFiles.push(url);
      this.changes()
    },
    deleteChecklistLender(key) {
      Swal.fire({
        title: 'Confirm',
        text: 'Are you sure you want to delete this checklist?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(res => {
        if (res.value) {
          const parsed_checklist = JSON.parse(this.lender_checklist);
          parsed_checklist.splice(key, 1);
          this.lender_checklist = JSON.stringify(parsed_checklist);

          this.changes();
        }
      })
    },
    deleteChecklist(key) {
      Swal.fire({
        title: 'Confirm',
        text: 'Are you sure you want to delete this checklist?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(res => {
        if (res.value) {
          const parsed_checklist = JSON.parse(this.checklist_json);
          parsed_checklist.splice(key, 1);
          this.checklist_json = JSON.stringify(parsed_checklist);

          this.changes();
        }
      })
    },
    addChecklist() {
      const new_checklist = {
        key: JSON.parse(this.checklist_json).length,
        label: this.new_checklist_label,
        value: 0,
      }

      const parsed_checklist = JSON.parse(this.checklist_json);
      parsed_checklist.push(new_checklist);

      this.checklist_json = JSON.stringify(parsed_checklist);
      this.new_checklist_label = '';
      this.changes();
    },
    addChecklistLender() {
      const new_checklist = {
        key: JSON.parse(this.lender_checklist).length,
        label: this.new_lender_checklist_label,
        value: 0,
      }

      const parsed_checklist = JSON.parse(this.lender_checklist);
      parsed_checklist.push(new_checklist);

      this.lender_checklist = JSON.stringify(parsed_checklist);
      this.new_lender_checklist_label = '';
      this.changes();
    },
    async saveInspection() {
      const parsed_checklist = JSON.parse(this.checklist_json);
      parsed_checklist.forEach(e => {
        e.value = this.checklistValue[e.key]
      })

      const parsed_checklist_lender = JSON.parse(this.lender_checklist);
      parsed_checklist_lender.forEach(e => {
        e.value = this.checklistValueLender[e.key]
      })

      this.checklist_json = JSON.stringify(parsed_checklist);
      this.lender_checklist = JSON.stringify(parsed_checklist_lender);

      const body = {
        checklist_json: this.checklist_json,
        lender_checklist: this.lender_checklist,
        enquiry_files: this.uploadedFiles,
      }

      const response = await customerService.inspection(this.customer_id, body);
      if (response) {
        Swal.fire({
          toast: true,
          title: 'Changes Saved',
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: 'success',
        });
      }
    },
    detectData() {
      if (this.form_data) {
        const {
          vehicle,
          enquiry: {
            files,
            prove_of_payments
          },
          inspection,
          comments
        } = this.form_data;

        this.existingFiles = files;
        this.prove_of_payments = prove_of_payments;
        this.comments = comments

        if (inspection) {
          this.checklist_json = inspection.checklist_json;
          JSON.parse(inspection.checklist_json).forEach(e => {
            this.checklistValue[e.key] = e.value
          })

          this.lender_checklist = inspection.lender_checklist;
          JSON.parse(inspection.lender_checklist).forEach(e => {
            this.checklistValueLender[e.key] = e.value
          })

        }

        const parsed_checklist = JSON.parse(this.checklist_json);
        if (this.installerIsSubmitted) {
          parsed_checklist[0].value = 1;
          this.checklist_json = JSON.stringify(parsed_checklist);
          JSON.parse(this.checklist_json).forEach(e => {
            this.checklistValue[e.key] = e.value
          })

          this.device_install_text = this.default_options[this.checklistValue[0]].text
        } else if (this.installerIsRearrange) {
          this.device_install_text = 'Re-Arranging'

        } else {
          if (vehicle) {
            if (vehicle.imei) {
              const parsed_checklist = JSON.parse(this.checklist_json);
              parsed_checklist[0].value = 2;

              this.checklist_json = JSON.stringify(parsed_checklist);
              JSON.parse(this.checklist_json).forEach(e => {
                this.checklistValue[e.key] = e.value
              })
              this.device_install_text = 'Completed'
            }
          }
        }

        parsed_checklist[0].value = 2;

        this.checklist_json = JSON.stringify(parsed_checklist);
        JSON.parse(this.checklist_json).forEach(e => {
          this.checklistValue[e.key] = e.value
        })
        this.device_install_text = 'Completed'
      }
    },
    assignData() {
      setTimeout(() => {
        this.detectData();
      }, 1000);
    },
  },
  mounted() {
    this.assignData();
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-md-12 card">
        <h4>Check List</h4>
        <div class="checklist">
          <div v-for="{ label, key }, i in parsedChecklist" :key="i">
            <div class="row">
              <div class="col-md-3" style="display: flex;align-items: center;">
                <span>{{ `${i + 1}.` }} {{ label }}</span>
              </div>
              <div class="col-md-2">
                <b-form-select v-if="i !== 0" :disabled="i == 0 || edit_permission"
                  style="padding: 0.47rem 0.75rem;font-size: 0.9rem;width: 100%;height: 100%;"
                  v-model="checklistValue[key]" :options="default_options" @change="changes"></b-form-select>
                <b-form-input v-else v-model="device_install_text" readonly></b-form-input>
              </div>
              <div class="col-md-1">
                <ul class="list-inline mb-0">
                  <li class="list-inline-item">
                    <a v-if="i !== 0 && !edit_permission" @click="deleteChecklist(i)" href="#" class="px-2 text-danger"><i
                        class="uil uil-trash-alt font-size-18"></i></a>
                  </li>
                </ul>
              </div>
              <div class="col-md-6">
                <div v-if="uploadedLabelFile(label)" style="display: flex;align-items: center;">
                  <div>
                    <span v-if="uploadedLabelFile(label).label" class="badge bg-success">{{ uploadedLabelFile(label).label }}</span>
                    <span v-else class="badge bg-danger">Others</span> &nbsp;
                  </div>
                  <a style="width: 50%;white-space: nowrap;display: block;overflow-x: hidden;text-overflow: ellipsis;" :href="uploadedLabelFile(label).url" target="_blank">{{ fileName(uploadedLabelFile(label).url) }}</a>
                  <a v-if="!edit_permission" @click="deleteFile(uploadedLabelFile(label).id)" href="#" class="px-2 text-danger"><i
                      class="uil uil-trash-alt font-size-18"></i></a>
                </div>
                <input v-else-if="!uploadedLabelFile(label) && (label != 'Device Installation')" type="file" class="form-control" :disabled="edit_permission" @change="uploadFileToLabel($event, label)" />
            </div>
            </div>
          </div>
        </div>
        <br>
        <div class="row" v-if="!edit_permission">
          <div class="col-md-4">
            <b-form-group label="Checklist Name" label-for="new_checklist_label" class="mb-3">
              <b-form-input v-model="new_checklist_label" id="new_checklist_label" type="text"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-2" style="align-items: center;">
            <b-form-group label="Add to Checklist" label-for="" class="mb-3">
              <b-button :disabled="new_checklist_label === ''" @click="addChecklist()" variant="primary"
                class="float-right" style="margin-right: 1rem">Submit</b-button>
            </b-form-group>
          </div>
        </div>
      </div>

      <div class="col-md-12 card" style="padding: 0.5rem;">
        <vue-dropzone v-if="!edit_permission" id="dropzone" ref="myVueDropzone" :use-custom-slot="true"
          :options="dropzoneOptions" @vdropzone-success="onFileUploadSuccess">
          <div class="dropzone-custom-content">
            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
            <h4>Drop files here or click to upload.</h4>
          </div>
        </vue-dropzone>

        <div v-for="(file, index) in existingFiles" :key="index">
          <div>
            <span v-if="file.label" class="badge bg-success">{{ file.label }}</span>
            <span v-else class="badge bg-danger">Others</span> &nbsp;
            <a :href="file.url" target="_blank">{{ fileName(file.url) }}</a>
            <a v-if="!edit_permission" @click="deleteFile(file.id)" href="#" class="px-2 text-danger"><i
                class="uil uil-trash-alt font-size-18"></i></a>
          </div>
        </div>
      </div>

      <div class="col-md-12 card">
        <h4>Lender's Check List</h4>
        <div class="checklist">
          <div v-for="{ label, key }, i in parsedLenderChecklist" :key="i">
            <div class="row">
              <div class="col-md-3" style="display: flex;align-items: center;">
                <span>{{ `${i + 1}.` }} {{ label }}</span>
              </div>
              <div class="col-md-2">
                <b-form-select v-if="i !== 0" :disabled="i == 0 || edit_permission"
                  style="padding: 0.47rem 0.75rem;font-size: 0.9rem;width: 100%;height: 100%;"
                  v-model="checklistValueLender[key]" :options="default_options" @change="changes"></b-form-select>
                <b-form-input v-else readonly v-model="default_options[checklistValueLender[key]].text "></b-form-input>
              </div>
              <div class="col-md-1">
                <ul class="list-inline mb-0">
                  <li class="list-inline-item">
                    <a v-if="i !== 0 && !edit_permission" @click="deleteChecklistLender(i)" href="#" class="px-2 text-danger"><i
                        class="uil uil-trash-alt font-size-18"></i></a>
                  </li>
                </ul>
              </div>
              <div class="col-md-6">
                <div v-if="uploadedProveOfPayment(label)" style="display: flex;align-items: center;">
                  <div>
                    <span v-if="uploadedProveOfPayment(label).label" class="badge bg-success">{{ uploadedProveOfPayment(label).label }}</span>
                    <span v-else class="badge bg-danger">Others</span> &nbsp;
                  </div>
                  <a style="width: 50%;white-space: nowrap;display: block;overflow-x: hidden;text-overflow: ellipsis;" :href="uploadedProveOfPayment(label).url" target="_blank">{{ fileName(uploadedProveOfPayment(label).url) }}</a>
                  <a v-if="!edit_permission" @click="deleteFile(uploadedProveOfPayment(label).id)" href="#" class="px-2 text-danger"><i
                      class="uil uil-trash-alt font-size-18"></i></a>
                </div>
            </div>
            </div>
          </div>
        </div>
        <br>
        <div class="row" v-if="!edit_permission">
          <div class="col-md-4">
            <b-form-group label="Checklist Name" label-for="new_lender_checklist_label" class="mb-3">
              <b-form-input v-model="new_lender_checklist_label" id="new_lender_checklist_label" type="text"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-2" style="align-items: center;">
            <b-form-group label="Add to Checklist" label-for="" class="mb-3">
              <b-button :disabled="new_lender_checklist_label === ''" @click="addChecklistLender()" variant="primary"
                class="float-right" style="margin-right: 1rem">Submit</b-button>
            </b-form-group>
          </div>
        </div>
      </div>

      <div class="col-md-12 card" style="min-height: 200px;">
        <h4>Lender's Documents</h4>
        <div v-for="(file, index) in prove_of_payments" :key="index">
          <div>
            <span v-if="file.label" class="badge bg-success">{{ file.label }}</span>
            <span v-else class="badge bg-danger">Others</span> &nbsp;
            <a :href="file.url" target="_blank">{{ fileName(file.url) }}</a>
          </div>
        </div>
      </div>
    </div>

    <br />
    <CommentSection 
      v-if="form_data?.id" 
      :allChecklistIsCompleted="allChecklistIsCompleted"
      :lender_checklist="lender_checklist"
      :prove_of_payments="prove_of_payments"
      :comments="comments"
      :customer_id="form_data?.id" 
      :status="3" 
      @refresh="getData" 
    />
  </div>
</template>

<style lang="scss"></style>
