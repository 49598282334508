<script>
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import customerService from '@/api/customerService';
import Swal from 'sweetalert2';
import CommentSection from '@/components/comment-section.vue';
import LoanScheduleList from '@/components/loan-schedule-list.vue';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import systemService from '@/api/systemService';
import axios from 'axios';

export default {
	name: 'LoanScheduleDraft',
	props: {
		form_data: {
			type: Object,
			default: () => {
				return {};
			}
		},
		getData: {
			type: Function,
			required: true
		},
		role_id: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			comments: [],
			existingFiles: [],
			uploadedFiles: [],
			checklist_json: `[
				{
					"key": 0,
					"label": "IC Card",
					"value": 0
				},
				{
					"key": 1,
					"label": "Driving License (Active)",
					"value": 0
				},
				{
					"key": 2,
					"label": "Road Tax (Active)",
					"value": 0
				},
				{
					"key": 3,
					"label": "Lastest Utility Bill",
					"value": 0
				},
				{
					"key": 4,
					"label": "3-Months Payslip",
					"value": 0
				},
				{
					"key": 5,
					"label": "3-Months Bank Statement",
					"value": 0
				},
				{
					"key": 6,
					"label": "EPF Statement",
					"value": 0
				},
				{
					"key": 7,
					"label": "Vehicle Photos",
					"value": 0
				},
				{
					"key": 8,
					"label": "Bank Infomation",
					"value": 0
				}
			]`,
			checklistValue: {
				0: 0,
				1: 0,
				2: 0,
				3: 0,
				4: 0,
				5: 0,
				6: 0,
				7: 0,
				8: 0
			},
			dropzoneOptions: {
				url: 'https://infinity-backend.braiven.com/upload',
				thumbnailWidth: 150,
				maxFilesize: 2
			},
			new_checklist_label: '',
			default_options: [
				{
					text: 'No Status',
					value: 0
				},
				{
					text: 'In Progress',
					value: 1
				},
				{
					text: 'Completed',
					value: 2
				}
			]
		};
	},
	computed: {
		progressPrevention() {
			return this.checkedList != JSON.parse(this.checklist_json).length;
		},
		totalList() {
			return JSON.parse(this.checklist_json).length;
		},
		checkedList() {
			return Object.values(this.checklistValue).filter((e) => e == 2).length;
		},
		customer_id() {
			return this.$route.query.id;
		},
		parsedChecklist() {
			return JSON.parse(this.checklist_json);
		},
		edit_permission() {
			const editable = [1, 2];
			return !editable.includes(this.role_id);
		}
	},
	methods: {
		uploadedLabelFile (label) {
			return this.existingFiles.find((e) => e.label == label);
		},
		changes() {
			clearTimeout(this.changesTimeout);
			this.changesTimeout = setTimeout(() => {
				this.saveLoan();
			}, 2000);
		},
		formValidation(e, type = 'text') {
			const emailRegex = /\S+@\S+\.\S+/;

			switch (type) {
				case 'email':
					return (
						e !== null && e !== undefined && e !== '' && emailRegex.test(e)
					);
				default:
					return e !== null && e !== undefined && e !== '';
			}
		},
		fileName(url) {
			const split_url = url.split('/');
			return split_url[split_url.length - 1];
		},
		uploadFileToLabel(file, label) {
			const uploadUrl = 'https://infinity-backend.braiven.com/upload'
			const formData = new FormData();
			formData.append('file', file.target.files[0]);

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			};

			axios.post(uploadUrl, formData, config)
				.then((response) => {
					const {
						files: {
							file: { url }
						}
					} = response.data
					this.uploadedFiles.push({
						label,
						url
					});
					this.changes();
				})
				.catch((error) => {
					console.log(error);
				});
		},
		onFileUploadSuccess(file, response) {
			const {
				files: {
					file: { url }
				}
			} = response;
			this.uploadedFiles.push({
				label: null,
				url
			});
			this.changes();
		},
		async saveLoan(body) {
			if (!body) {
				body = {};
			}
			const parsed_checklist = JSON.parse(this.checklist_json);
			parsed_checklist.forEach((e) => {
				e.value = this.checklistValue[e.key];
			});
			this.checklist_json = JSON.stringify(parsed_checklist);
			body.checklist = this.checklist_json;

			if (this.uploadedFiles.length) {
				body.enquiry_files = this.uploadedFiles;
			}

			if (!this.customer_id) {
				return;
			}

			if (!body.price) {
				delete body.price;
			}

			if (!body.selected_years) {
				body.selected_years = 1;
			}

			const response = await customerService.loan(this.customer_id, body);
			if (response) {
				Swal.fire({
					toast: true,
					position: 'top-end',
					showConfirmButton: false,
					title: 'Loan Schedule Saved',
					timer: 3000,
					icon: 'success'
				});
			}
		},
		detectData() {
			if (this.form_data) {
				const {
					enquiry: { files },
					comments
				} = this.form_data;

				this.comments = comments;
				this.existingFiles = files;

				if (this.form_data.loan) {
					const { checklist } = this.form_data.loan;
					this.checklist_json = checklist;
					const parsed_checklist = JSON.parse(this.checklist_json);
					parsed_checklist.forEach((e) => {
						this.checklistValue[e.key] = e.value;
					});
				}
			}
		},
		deleteChecklist(key) {
			Swal.fire({
				title: 'Confirm',
				text: 'Are you sure you want to delete this checklist?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No'
			}).then((res) => {
				if (res.value) {
					const parsed_checklist = JSON.parse(this.checklist_json);
					parsed_checklist.splice(key, 1);
					this.checklist_json = JSON.stringify(parsed_checklist);

					this.changes();
				}
			});
		},
		addChecklist() {
			const new_checklist = {
				key: JSON.parse(this.checklist_json).length,
				label: this.new_checklist_label,
				value: 0
			};

			const parsed_checklist = JSON.parse(this.checklist_json);
			parsed_checklist.push(new_checklist);

			this.checklist_json = JSON.stringify(parsed_checklist);
			this.new_checklist_label = '';
			this.changes();
		},
		assignData() {
			setTimeout(() => {
				this.detectData();
			}, 1000);
		},
		deleteFile(id) {
			Swal.fire({
				title: 'Confirm',
				text: 'Are you sure you want to delete this file?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No',
			}).then(async (result) => {
				if (result.value) {
				const response = await systemService.deleteFile(id);
				if (response) {
					Swal.fire({
					toast: true,
					position: 'top-end',
					showConfirmButton: false,
					timer: 3000,
					icon: 'success',
					});
					this.$emit('refresh')
					this.assignData()
				}
				}
			})
			},
	},
	mounted() {
		this.assignData();
	},
	components: {
		CommentSection,
		vueDropzone: vue2Dropzone,
		LoanScheduleList
	}
};
</script>

<template>
	<div>
		<div class="accordion" role="tablist">
			<b-card no-body class="mb-1">
				<b-card-header header-tag="header" class="p-1" role="tab">
					<b-button class="accordion-button" v-b-toggle.accordion-1 variant="primary">Loan Schedule</b-button>
				</b-card-header>

				<b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
					<b-card-body>
						<LoanScheduleList 
							:edit_permission="edit_permission"
							:form_data="form_data"
							:role_id="role_id" 
							:loan="form_data?.loan"
							@saveLoan="saveLoan"
						/>
					</b-card-body>
				</b-collapse>
			</b-card>

			<b-card no-body class="mb-1">
				<b-card-header header-tag="header" class="p-1" role="tab">
					<b-button class="accordion-button" v-b-toggle.accordion-2 variant="primary">Documents (Completed: {{
						checkedList }}/{{ totalList }})</b-button>
				</b-card-header>
				<b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
					<b-card-body>
						<div class="row">
							<div class="col-md-12 card">
								<h4>Required Documents Check List </h4>
								<div class="checklist">
									<div v-for="({ label, key }, i) in parsedChecklist" :key="i">
										<div class="row">
											<div class="col-md-3" style="display: flex; align-items: center">
												<span>{{ `${i + 1}.` }} {{ label }}</span>
											</div>
											<div class="col-md-2">
												<b-form-select style="
														padding: 0.47rem 0.75rem;
														font-size: 0.9rem;
														width: 100%;
														height: 100%;
													" :state="formValidation(checklistValue[key])" :disabled="edit_permission" required @change="changes"
													v-model="checklistValue[key]"
													:options="default_options"></b-form-select>
											</div>
											<div class="col-md-1">
												<ul class="list-inline mb-0">
													<li class="list-inline-item">
														<a v-if="!edit_permission" @click="deleteChecklist(key)" href="#"
															class="px-2 text-danger"><i
																class="uil uil-trash-alt font-size-18"></i></a>
													</li>
												</ul>
											</div>
											<div class="col-md-6">
												<div v-if="uploadedLabelFile(label)" style="display: flex;align-items: center;">
													<div>
														<span v-if="uploadedLabelFile(label).label" class="badge bg-success">{{ uploadedLabelFile(label).label }}</span>
														<span v-else class="badge bg-danger">Others</span> &nbsp;
													</div>
													<a style="width: 50%;white-space: nowrap;display: block;overflow-x: hidden;text-overflow: ellipsis;" :href="uploadedLabelFile(label).url" target="_blank">{{ fileName(uploadedLabelFile(label).url) }}</a>
													<a v-if="!edit_permission" @click="deleteFile(uploadedLabelFile(label).id)" href="#" class="px-2 text-danger"><i
															class="uil uil-trash-alt font-size-18"></i></a>
												</div>
												<input v-else type="file" class="form-control" :disabled="edit_permission" @change="uploadFileToLabel($event, label)" />
											</div>
										</div>
									</div>
								</div>
								<br />
								<div class="row" v-if="!edit_permission">
									<div class="col-md-4">
										<b-form-group label="Checklist Name" label-for="new_checklist_label" class="mb-3">
											<b-form-input @change="changes" v-model="new_checklist_label"
												id="new_checklist_label" type="text"></b-form-input>
										</b-form-group>
									</div>
									<div class="col-md-2" style="align-items: center">
										<b-form-group label="Add to Checklist" label-for="" class="mb-3">
											<b-button :disabled="new_checklist_label === ''" @click="addChecklist()"
												variant="primary" class="float-right"
												style="margin-right: 1rem">Submit</b-button>
										</b-form-group>
									</div>
								</div>
							</div>
							<div class="col-md-12 card" style="padding: 0.5rem">
								<vue-dropzone v-if="!edit_permission" id="dropzone" ref="myVueDropzone" :use-custom-slot="true"
									:options="dropzoneOptions" @vdropzone-success="onFileUploadSuccess">
									<div class="dropzone-custom-content">
										<i class="display-4 text-muted bx bxs-cloud-upload"></i>
										<h4>Drop files here or click to upload.</h4>
									</div>
								</vue-dropzone>
								
								<div v-for="(file, index) in existingFiles" :key="index">
									<div>
										<span v-if="file.label" class="badge bg-success">{{ file.label }}</span>
										<span v-else class="badge bg-danger">Others</span> &nbsp;
										<a :href="file.url" target="_blank">{{ fileName(file.url) }}</a>
										<a v-if="!edit_permission" @click="deleteFile(file.id)" href="#" class="px-2 text-danger"><i
												class="uil uil-trash-alt font-size-18"></i></a>
									</div>
								</div>
							</div>
						</div>
					</b-card-body>
				</b-collapse>
			</b-card>
		</div>

		<br />
		<CommentSection 
			v-if="form_data?.id" 
			:form_data="form_data"
			:progressPrevention="progressPrevention" 
			:comments="comments"
			:customer_id="form_data?.id" 
			:lender_id="form_data?.lender_id" 
			:edit_permission="role_id != 2"
			:status="1"
			@refresh="getData" />
	</div>
</template>

<style lang="scss"></style>
