import httpClient from './httpClient';

export default {
	async index(role_id) {
		const res = await httpClient({ requiresAuth: true }).get('/staffs'+(role_id ? `?role_id=${role_id}` : ''));

		return res.data;
	},
	async store(payload) {
		const res = await httpClient({ requiresAuth: true }).post('/staffs', payload);

		return res.data;
	},
	async show(id) {
		const res = await httpClient({ requiresAuth: true }).get(`/staffs/${id}`);

		return res.data;
	},
	async update(payload, id) {
		const res = await httpClient({ requiresAuth: true }).put(`/staffs/${id}`, payload);

		return res.data;
	},
	async destroy(id) {
		const res = await httpClient({ requiresAuth: true }).delete(`/staffs/${id}`);

		return res.data;
	},
	async createComment(id, payload) {
		const res = await httpClient({ requiresAuth: true }).post(`/comment/${id}`, payload);

		return res.data;
	}
};
