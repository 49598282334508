<script>
import Swal from 'sweetalert2';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import customerService from '@/api/customerService.js';
import systemService from '@/api/systemService.js';
import CommentSection from '@/components/comment-section.vue';
import Vehicle from './Vehicle.vue';

export default {
  components: {
    vueDropzone: vue2Dropzone,
    CommentSection,
    Vehicle
},
  data() {
    return {
      selected: null,
      options: [
        { value: null, text: 'Please select an option' },
        { value: 'yes', text: 'Yes' },
        { value: 'no', text: 'No' },
      ],
      states: [],
      customerSources: [
        { value: null, text: 'Please select an option' },
        { value: 'Berdaya', text: 'Berdaya' },
        { value: 'Boon Hua', text: 'Boon Hua' },
        { value: 'Whatsapp', text: 'Whatsapp' },
      ],
      dropzoneOptions: {
        url: 'https://infinity-backend.braiven.com/upload',
        thumbnailWidth: 150,
        maxFilesize: 10,
      },
      existingFiles: [],
      uploadedFiles: [],
      system_no: null,
      car_grant_price: null,
      customer_name: null,
      road_tax_status: null,
      ic_no: null,
      road_tax_expiry: null,
      email: null,
      salesperson: null,
      date_of_application: new Date().toISOString().substr(0, 10),
      telephone: null,
      state: null,
      customerSource: null,
      remark: null,

      comments: [],

      customer_panel: true,
      vehicle_panel: true,
      documents_panel: false,
      callback: null
    };
  },
  props: {
    form_data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    edit_permission: {
      type: Boolean,
      default: false,
    },
    getData: {
      type: Function,
      required: true
    }
  },
  methods: {
    formValidation(e, type = 'text') {
      const emailRegex = /\S+@\S+\.\S+/;

      switch (type) {
        case 'email':
          return e !== null && e !== undefined && e !== '' && emailRegex.test(e);
        default:
          return e !== null && e !== undefined && e !== '';
      }
    },
    submitHandler() {
      this.$refs.enquirySubmit.click()
    },
    fileName(url) {
			const split_url = url.split('/');
			return split_url[split_url.length - 1];
		},
		onFileUploadSuccess(file, response) {
			const {
				files: {
					file: { url }
				}
			} = response;
			this.uploadedFiles.push(url);
		},
    async detectData() {
      if (this.form_data) {
        const {
          email,
          telephone,
          name: customer_name,
          enquiry: {
            system_no,
            car_grant_price,
            road_tax_status,
            ic_no,
            road_tax_expiry,
            date_of_application,
            state,
            remark,
            files
          },
          comments
        } = this.form_data;

        this.system_no = system_no;
        this.car_grant_price = car_grant_price;
        this.customer_name = customer_name;
        this.road_tax_status = road_tax_status? true:false;
        this.ic_no = ic_no;
        this.road_tax_expiry = road_tax_expiry? new Date(road_tax_expiry).toISOString().substr(0, 10):null;
        this.email = email;
        this.date_of_application = new Date(date_of_application).toISOString().substr(0, 10);
        this.telephone = telephone;
        this.state = state;
        this.remark = remark;
        this.existingFiles = files

        this.comments = comments
      } else {
        this.system_no = await systemService.getSystemNo();
      }
    },
    deleteFile(id) {
      Swal.fire({
        title: 'Confirm',
        text: 'Are you sure you want to delete this file?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async (result) => {
        if (result.value) {
          const response = await systemService.deleteFile(id);
          if (response) {
            Swal.fire({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              icon: 'success',
            });
            this.$emit('refresh')
            this.assignData()
          }
        }
      })
    },
    convertEmptyStringToNull(obj) {
      for (const key in obj) {
        if (obj[key] === '') {
          obj[key] = null;
        }
      }
      return obj;
    },
    saveEnquiry() {
      var body = {
        customer: {
          name: this.customer_name,
          email: this.email,
          telephone: this.telephone,
        },
        enquiry: {
          system_no: this.system_no,
          car_grant_price: this.car_grant_price,
          road_tax_status: this.road_tax_status,
          ic_no: this.ic_no,
          road_tax_expiry: this.road_tax_expiry == ''? null : this.road_tax_expiry,
          date_of_application: this.date_of_application == ''? null : this.date_of_application,
          state: this.state,
          remark: this.remark,
        },
        enquiry_files: this.uploadedFiles,
      }
    
      body.customer = this.convertEmptyStringToNull(body.customer)
      body.enquiry = this.convertEmptyStringToNull(body.enquiry)

      Swal.fire({
        title: 'Confirm',
        text: 'Are you sure you want to submit this enquiry?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async (result) => {
        if (result.value) {
          const response = await customerService.post(body);
          if (response) {
            this.$refs.vehicleForm.saveVehicle(response.data.id)
            Swal.fire({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              icon: 'success',
            });

            this.$router.push({
              name: 'customer-id',
              query: {
                id: response.data.id,
              }
            });

            setTimeout(() => {
              location.reload()
            }, 100);
          }
        }
      })
    },
    assignData() {
      setTimeout(() => {
        this.detectData();
      }, 1000);
    },
    initOptions() {
      systemService.getCommons('State').then((response) => {
        this.states = response.map((item) => {
          return {
            value: item.name,
            text: item.name,
          };
        });
      });
    },
    onSubmit(e) {
      e.preventDefault();
      this.saveEnquiry()
    }
  },
  mounted() {
    this.assignData();
    this.initOptions()
  }
};
</script>

<template>
  <b-form @submit="onSubmit">
    <div class="accordion" role="tablist">
			<b-card no-body class="mb-1">
				<b-card-header header-tag="header" class="p-1" role="tab">
					<b-button class="accordion-button" @click="customer_panel = !customer_panel" variant="primary">Customer Info</b-button>
				</b-card-header>
				<b-collapse v-model="customer_panel">
					<b-card-body>
            <div class="row">
              <div class="col-md-6">
                <b-form-group label="System No" label-for="system_no" class="mb-3">
                  <b-form-input :disabled="true" v-model="system_no" id="system_no" type="text" :state="formValidation(system_no)" ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label="Car Grant (RM)" label-for="car_grant_price" class="mb-3">
                  <b-form-input :disabled="edit_permission" v-model="car_grant_price" id="car_grant_price" type="number" ></b-form-input>
                </b-form-group>
              </div>
              
            </div>
            <div class="row">
              <div class="col-md-6">
                <b-form-group
                  label="Customer Name"
                  label-for="customer_name"
                  class="mb-3"
                >
                  <b-form-input :disabled="edit_permission" v-model="customer_name" id="customer_name" type="text" :state="formValidation(customer_name)" required ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group
                  label="Road Tax Status"
                  label-for="road_tax_status"
                  class="mb-3"
                >
                  <b-form-select
                    id="road_tax_status"
                    style="
                      padding: 0.47rem 0.75rem;
                      font-size: 0.9rem;
                      width: 100%;
                      height: 100%;
                    "
                    :disabled="edit_permission" 
                    v-model="road_tax_status"
                    :options="[
                      { value: null, text: 'Please select an option' },
                      { value: true, text: 'Yes' },
                      { value: false, text: 'No' },
                    ]"
                  ></b-form-select>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <b-form-group label="IC Number" label-for="ic_number" class="mb-3">
                  <b-form-input :disabled="edit_permission" v-model="ic_no" id="ic_number" type="text" :state="formValidation(ic_no)" required ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group
                  label="Road Tax Expiry Date"
                  label-for="road_tax_expiry"
                  class="mb-3"
                >
                  <b-form-input :disabled="edit_permission" v-model="road_tax_expiry" id="road_tax_expiry" type="date" ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <b-form-group
                  label="Email Address"
                  label-for="email"
                  class="mb-3"
                >
                  <b-form-input :disabled="edit_permission" v-model="email" id="email" type="email" :state="formValidation(email, 'email')" required></b-form-input>
                </b-form-group>
              </div>

              <div class="col-md-6">
                <b-form-group
                  label="Phone Number"
                  label-for="telephone"
                  class="mb-3"
                >
                  <b-form-input :disabled="edit_permission" v-model="telephone" id="telephone" type="text" :state="formValidation(telephone)" required ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <b-form-group label="State" label-for="state" class="mb-3">
                  <b-form-select
                    style="
                      padding: 0.47rem 0.75rem;
                      font-size: 0.9rem;
                      width: 100%;
                      height: 100%;
                    "
                    :disabled="edit_permission" v-model="state"
                    :options="states"
                    
                  ></b-form-select>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group
                  label="Date of Application"
                  label-for="date_of_application"
                  class="mb-3"
                >
                  <b-form-input :disabled="edit_permission" v-model="date_of_application" id="date_of_application" type="date" :state="formValidation(date_of_application)" ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <b-form-group label="Customer Source" label-for="customerSource" class="mb-3">
                  <b-form-select
                    style="
                      padding: 0.47rem 0.75rem;
                      font-size: 0.9rem;
                      width: 100%;
                      height: 100%;
                    "
                    :disabled="edit_permission" v-model="customerSource"
                    :options="customerSources"
                  ></b-form-select>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group
                  label="Salesperson In Charge"
                  label-for="salesperson"
                  class="mb-3"
                >
                  <b-form-input :disabled="edit_permission" v-model="salesperson" id="salesperson" type="text" ></b-form-input>
                </b-form-group>
              </div>
            </div>
					</b-card-body>
				</b-collapse>
			</b-card>

			<b-card no-body class="mb-1">
				<b-card-header header-tag="header" class="p-1" role="tab">
					<b-button class="accordion-button" @click="vehicle_panel = !vehicle_panel" variant="primary">Vehicle Info</b-button>
				</b-card-header>
				<b-collapse v-model="vehicle_panel">
					<b-card-body>
            <Vehicle
              ref="vehicleForm"
              :form_data="form_data?.vehicle"
              :edit_permission="edit_permission"
              @refresh="getData"
            />
					</b-card-body>
				</b-collapse>
			</b-card>

      <b-card no-body class="mb-1">
				<b-card-header header-tag="header" class="p-1" role="tab">
					<b-button class="accordion-button" @click="documents_panel = !documents_panel" variant="primary">Documents</b-button>
				</b-card-header>
				<b-collapse v-model="documents_panel">
					<b-card-body>
            <div class="row">
              <div
                class="col-md-12 card"
                style="padding: 0.5rem">
                <vue-dropzone v-if="!edit_permission"
                  id="dropzone"
                  ref="myVueDropzone"
                  :use-custom-slot="true"
                  :options="dropzoneOptions"
                  @vdropzone-success="onFileUploadSuccess">
                  <div class="dropzone-custom-content">
                    <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                    <h4>Drop files here or click to upload.</h4>
                  </div>
                </vue-dropzone>

                <div v-for="(file, index) in existingFiles" :key="index">
									<div>
										<span v-if="file.label" class="badge bg-success">{{ file.label }}</span>
										<span v-else class="badge bg-danger">Others</span> &nbsp;
										<a :href="file.url" target="_blank">{{ fileName(file.url) }}</a>
										<a v-if="!edit_permission" @click="deleteFile(file.id)" href="#" class="px-2 text-danger"><i
												class="uil uil-trash-alt font-size-18"></i></a>
									</div>
								</div>
              </div>
            </div>
					</b-card-body>
				</b-collapse>
			</b-card>
      
		</div>
    <br>
    <b-button v-if="!edit_permission" ref="enquirySubmit" type="submit" variant="primary" >
      {{ form_data?.id ? 'Save Document' : 'Save Document' }}
    </b-button>

    <CommentSection
      v-if="form_data?.id"
      :comments="comments"
      :customer_id="form_data?.id"
      :status="0"
      @refresh="getData"
    />
  </b-form>
</template>

<style lang="scss"></style>
