<script>
import Layout from '../../layouts/main';
// import { Carousel, Slide } from "vue-carousel";
import { FormWizard, TabContent } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import Enquiry from './Form/Enquiry';
import LoanScheduleDraft from './Form/LoanSchedule';
import Swal from 'sweetalert2';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import customerService from '../../../api/customerService';
import RequireInstall from '@/views/pages/customer/Form/installer.vue';
import Inspection from '@/views/pages/customer/Form/Inspection.vue';
import Live from '@/views/pages/customer/Form/Live.vue';

export default {
  components: {
    Layout,
    Enquiry,
    LoanScheduleDraft,
    // Carousel,
    // Slide,
    FormWizard,
    TabContent,
    RequireInstall,
    Inspection,
    Live
},
  data() {
    return {
      progress: [
        {
          title: 'Enquiry',
          icon: 'uil-file-plus',
        },
        {
          title: 'Loan Schedule & Documents',
          icon: 'uil-schedule',
        },
        {
          title: 'Require Install',
          icon: 'uil-processor',
        },
        {
          title: 'Final Inspection & Loan Disbursement',
          icon: 'uil-file-search-alt',
        },
        {
          title: 'Live',
          icon: 'uil-check',
        },
      ],
      form: null,
      formStatus: 0,
      loaded: true
    };
  },
  computed: {
    onEditId() {
      return this.$route.query.id;
    },
    formWizard() {
      return this.$refs.formWizard;
    },
    formProgress() {
      return [
        {
          title: 'Require Install',
          icon: 'uil-processor',
        },
      ]
    },
    role_id() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      
      return userData? userData.role_id : null
    },
    edit_permission() {
      const editable = [1,2]
      return !editable.includes(this.role_id)
    }
  },
  methods: {
    beforeChange() {
      return false
    },
    handleStepChange() {
      return new Promise((resolve, reject) => {
        reject()
     })
    },
    async saveEnquiry() {
      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
      });
    },
    async getData() {
      this.loaded = false
      //Loading Swal
      Swal.fire({
        title: 'Loading...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading()
        },
        showConfirmButton: false,
      });

      this.form = await customerService.show(this.onEditId)
      this.formStatus = this.form.status
      if (this.role_id == 3 && this.formStatus == 3) {
        const target = this.form.comments.filter(e => e.staff.role_id == 3 && e.case.includes('Install'))
        if (target.length) {
          this.formStatus = 3
        } else {
          this.formStatus = 2
        }
      }
      setTimeout(() => {
        Swal.close()
      }, 1000);

      this.loaded = true
    }
  },
  async mounted() {
    if (this.onEditId) {
      this.getData()
    }
  }
};
</script>

<template>
  <Layout>
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Customer Information: {{ form?.name }}</h4>
          <form-wizard v-if="loaded && role_id != 5" :startIndex="formStatus" refs="form-wizard" color="#5b73e8">
            <tab-content v-for="({ title, icon }, i) in progress" :title="title" :icon="icon" :key="i">
              <div class="row">
                <div class="col-lg-12">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">{{ title }}</h4>
                      <div class="mt-4">
                        <div v-if="i === 0">
                          <Enquiry
                            :edit_permission="edit_permission"
                            :form_data="form"
                            :getData="getData"
                            :role_id="role_id"
                            @refresh="getData"
                          />
                        </div>
                        <div v-if="i === 1">
                          <LoanScheduleDraft
                            :edit_permission="edit_permission"
                            :form_data="form"
                            :getData="getData"
                            :role_id="role_id"
                            @refresh="getData"
                          />
                        </div>
                        <div v-if="i === 2">
                          <RequireInstall
                            :edit_permission="edit_permission"
                            :form_data="form"
                            :getData="getData"
                            :role_id="role_id"
                            @refresh="getData"
                          />
                        </div>
                        <div v-if="i === 3">
                          <Inspection
                            :edit_permission="edit_permission"
                            :form_data="form"
                            :getData="getData"
                            :role_id="role_id"
                            @refresh="getData"
                          />
                        </div>
                        <div v-if="i === 4">
                          <Live
                            :edit_permission="false"
                            :form_data="form"
                            :getData="getData"
                            :role_id="role_id"
                            @refresh="getData"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </tab-content>
          </form-wizard>
          <form-wizard v-if="loaded && role_id == 5"  :startIndex="formStatus" refs="form-wizard" color="#5b73e8">
            <tab-content v-for="({ title, icon }, i) in formProgress" :title="title" :icon="icon" :key="i">
              <div class="row">
                <div class="col-lg-12">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">{{ title }}</h4>
                      <div class="mt-4">
                        <RequireInstall
                          :edit_permission="edit_permission"
                          :form_data="form"
                          :role_id="role_id"
                          :getData="getData"
                          @refresh="getData"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </tab-content>
          </form-wizard>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss">
.wizard-card-footer {
  display: none;
}
.boxed {
  border: 5px solid #ebeff2;
  border-radius: 8px;
  padding: 10px;
  height: 75px;
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
